import { environment } from './../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic YWRtaW46YWRtaW4='
  })
};
export interface IShared {


}

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private httpClient: HttpClient) { }
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  handleError(error: Response) {
    console.error(error);
    return Observable.throw(error);
  }
  getAllList(): Observable<IShared[]> {
    return this.httpClient.get<IShared[]>(environment.api + '/listOrder', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  save(formData) {
    return this.httpClient.post(`${environment.api}/addUpdateOrder`, formData, httpOptions).pipe(map((response) => {
      return response
    }), catchError(this.handleError))
  }
  saveOrderItem(formData) {
    return this.httpClient.post(`${environment.api}/addUpdateOrderItem`, formData, httpOptions).pipe(map((response) => {
      return response
    }), catchError(this.handleError))
  }

  getListById(id: any) {
    return this.httpClient.post(environment.api + '/listOrderById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  getAllItemList(): Observable<IShared[]> {
    return this.httpClient.get<IShared[]>(environment.api + '/listOrderItem', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));
  }
  deleteRecordById(id: any) {

    return this.httpClient.delete(environment.api + '/deleteOrder/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getClientList(): Observable<IShared[]> {
    return this.httpClient.get<IShared[]>(environment.api + '/listClient', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));
  }
  getAddressList(): Observable<IShared[]> {
    return this.httpClient.get<IShared[]>(environment.api + '/listClientAddress', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));
  }
  getAddressListById(id: any): Observable<[]> {
    return this.httpClient.post<[]>(environment.api + '/listClientAddressById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));
  }
  getProductList(): Observable<IShared[]> {
    return this.httpClient.get<IShared[]>(environment.api + '/listPricelistItem', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));
  }
  getProductListById(id: any): Observable<[]> {
    return this.httpClient.post<[]>(environment.api + '/listPricelistItemById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));
  }
  getListOrderItem(): Observable<IShared[]> {
    return this.httpClient.get<IShared[]>(environment.api + '/listOrderItem', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));
  }

  getOrderById(id: any): Observable<IShared[]> {
    return this.httpClient.post<IShared[]>(environment.api + '/listplaceOrder', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getOrdersByClient(id: any): Observable<IShared[]> {
    return this.httpClient.post<IShared[]>(environment.api + '/listOrderById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getOrderListById(id: any): Observable<IShared[]> {
    return this.httpClient.post<IShared[]>(environment.api + '/listOrderItemById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  deleteItemRecordById(id: any) {
    return this.httpClient.delete(environment.api + '/deleteOrderItem/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  addUpdateOrderSchedule(schduleDto) {
    return this.httpClient.post(`${environment.api}/addUpdateOrderSchedule`, schduleDto, httpOptions).pipe(map((response) => {
      return response
    }), catchError(this.handleError))
  }
  getOrderSchedules(): Observable<IShared[]> {
    return this.httpClient.get<IShared[]>(environment.api + '/listOrderSchedule', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));
  }

}
