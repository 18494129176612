import { catchError, map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic YWRtaW46YWRtaW4='
  })
};
export interface IShared {


}
@Injectable({
  providedIn: 'root'
})
export class DriverUserService {
  constructor(private httpClient: HttpClient) { }
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  handleError(error: Response) {
    console.error(error);
    return Observable.throw(error);
  }

  save(formData) {
    return this.httpClient.post(`${environment.api}/addUpdateUser`, formData, httpOptions).pipe(map((response) => {
      return response
    }), catchError(this.handleError))
  }
  getAllListById(id: any): Observable<IShared[]> {
    return this.httpClient.post<IShared[]>(environment.api + '/listUserById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getAllList(): Observable<IShared[]> {
    return this.httpClient.get<IShared[]>(environment.api + '/listUser/', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  deleteAllListById(id: any) {
    // /deleteUser/{cUserId}
    return this.httpClient.delete(environment.api + '/deleteUser/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getRoleList(): Observable<IShared[]> {
    return this.httpClient.get<IShared[]>(environment.api + '/listRole', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));
  }
}
