import { Injectable } from '@angular/core';
import { HttpModule, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, retry, retryWhen, delay, scan } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic YWRtaW46YWRtaW4='
  })
};

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private _http: HttpClient) { }

  getClientlist(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listClient', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  clientAdd(clientInfo: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateClient', clientInfo, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  clientDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteClient/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  getRole(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listRole', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getWindow(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listWindow', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }



  getRoelWindow(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listRoleWindowAccess', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  getSupplierlist(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listClientAddress', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  getAddresslist(id: any): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listClientAddressById/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getClientUser(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listUser', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  getCustomerCCMByClId(clientInfo: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/listCustomerContractMatrixBycClientId', clientInfo, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  getRoleWindowId(clientInfo: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/listRoleWindowAccessById', clientInfo, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  addRoleWindow(clientWHInfo: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateRoleWindowAccess', clientWHInfo, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }
  deleteRoleW(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteRoleWindowAccess/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }
  clientSupAdd(clientSupInfo: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateClientAddress', clientSupInfo, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  clientUserAdd(custMatrixInfo: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateUser', custMatrixInfo, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  clientWHDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteClientWarehouse/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  clientSupDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteClientSupplierAddress/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  adderssDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteClientAddress/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  userDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteUser/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  getCWHById(clientWHInfo: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/listClientWarehouseByClientId', clientWHInfo, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  getSupById(clientSupInfo: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/listClientSupplierAddressById', clientSupInfo, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  getCCMById(clientCCMInfo: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/listCustomerContractMatrixBycClientId', clientCCMInfo, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  handleError(error: Response) {
    console.error(error);
    return Observable.throw(error);
  }
}
