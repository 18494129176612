import { Injectable } from '@angular/core';
import { HttpModule, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, retry, retryWhen, delay, scan } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({


    'Content-Type': 'application/json',
    'Authorization': 'Basic YWRtaW46YWRtaW4='

  })
};

const httpOptionsMultipart = {
  headers: new HttpHeaders({
    'Authorization': 'Basic YWRtaW46YWRtaW4='
  })
};


@Injectable({
  providedIn: 'root'
})
export class ProductApisService {

  constructor(private _http: HttpClient) { }

  /* Apis for Product Category */

  getProdCategorylist(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listProductCategory', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  prodCategoryAddUpdate(prodCatDto: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateProductCategory', prodCatDto, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  prodCategoryDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteProductCategory/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  prodCategoryGetById(idObj: any): Observable<any[]> {
    return this._http.post<any[]>(environment.api + '/listProductCategoryById', idObj, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  /* Apis for product */

  getProdlist(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listProduct', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  productAddUpdate(prodCatDto: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateProduct', prodCatDto, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  productDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteProduct/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  productGetById(idObj: any): Observable<any[]> {
    return this._http.post<any[]>(environment.api + '/listProductById', idObj, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  getProdSubCategorylist(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listProductSubCategory', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  getProdSubCategorylistByCategoryId(catId: any): Observable<any[]> {
    return this._http.post<any[]>(environment.api + '/listProductSubCategoryById', catId, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  addProductImage(addImage: any): Observable<any[]> {
    return this._http.post<any[]>(environment.api + '/addUpdateProductImage', addImage, httpOptionsMultipart).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  handleError(error: Response) {
    console.error(error);
    return Observable.throw(error);
  }
  addimage(addImage: any): Observable<any[]> {
    return this._http.post<any[]>(environment.api + '/addProductCategoryImage', addImage, httpOptionsMultipart).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  
/* Import-Export Products Functionality */
  exportProducts(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/octet-stream');
    headers = headers.set('Authorization', 'Basic YWRtaW46YWRtaW4=')
    return this._http.get<any[]>(environment.api + '/export/products.xlsx',
    { headers: headers, responseType: 'blob' as 'json' }).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  importProducts(excelFile: any): Observable<any[]> {
    return this._http.post<any[]>(environment.api + '/import/productexcelfile', excelFile, httpOptionsMultipart).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  /* Import-Export Product Price List Functionality */
  exportProdPriceList(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/octet-stream');
    headers = headers.set('Authorization', 'Basic YWRtaW46YWRtaW4=')
    return this._http.get<any[]>(environment.api + '/export/productListItem.xlsx',
    { headers: headers, responseType: 'blob' as 'json' }).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  importProductPriceList(excelFile: any): Observable<any[]> {
    return this._http.post<any[]>(environment.api + '/import/productlistItemExcelfile', excelFile, httpOptionsMultipart).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  

}
